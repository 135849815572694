.profilePicWrapper {
  
  .addArea {
    position: relative;
    // top: 0;
    aspect-ratio: 1 / 1;
    transform: translate(0px, -268px);
    object-fit: cover;
    @media screen and (min-width: 576px)  {
      // top: -0.4rem;
      // top:-20rem;
      // transform: translate(0px, -513px);
    }
    @media screen and (min-width: 768px)  {
      top: 10.5rem;
    }
    @media (min-width: 992px) { 
      top: 4.0rem;
     }
    @media screen and (min-width: 1200px) {
      top: -0.4rem;
      
    }
    
    
  }
  .addButton {
    
    border-width: 0;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;

    svg {
      font-size: 4rem;
      line-height: 6rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .removeButton {
    
    position: absolute;
    top: 2.6rem;
    right: 2.6rem;
    border-width: 0;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 2rem;
    }

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 576px) {
      right: 6rem;
      top: 5.2rem;
    }
    @media screen and (min-width: 768px) {
      top: 0.5rem;
      right: 1rem;
    }
    @media screen and (min-width: 992px) {
      top: 2rem;
      right: 1.2rem;
    }
    @media screen and (min-width: 1200px) {
      top: 2rem;
      right: 2.6rem;
    }
  }

  .badges {
    position: relative;
    margin-top: -5rem;
    // transform: translate(0px, -265px);

    .badge {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-left: -2.5rem;
    }
  }

  .badgesTransformed {
    position: relative;
    margin-top: -5rem;
    transform: translate(0px, -265px);

    .badge {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      margin-left: -2.5rem;
    }
  }
}
