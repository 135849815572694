@use '../../../scss/variables' as vars;

.ContestRowBigWrapper {
  border-radius: vars.$radius-s;
  overflow: hidden;

  .ContestRowBigImage {
    // border: 1px solid red;
    @media screen and (min-width: 1200px) {
      min-width: 280px;
      height: 280px;
      max-width: 280px;
      object-fit: fill;

    }

    img {
      border: 1px solid blue;
      width: 100%;
      height: 100%;
      object-fit: fill;

    }
  }

  .ContestRowBigImagePlaceholder {
    border-radius: vars.$radius-s;
    height: 9rem;
    width: 9rem;
    background-color: vars.$color-light-gray-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }

  .statusBar {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .ContestRowBigGallery {
    overflow: hidden;

    & > div:first-child {
      overflow: hidden;
      border-radius: vars.$radius-s 0 0 vars.$radius-s;
    }

    & > div:last-child {
      overflow: hidden;
      border-radius: 0 vars.$radius-s vars.$radius-s 0;
    }

    img {
      width: 9rem;
      height: 9rem;
      object-fit: cover;
    }

    .ContestRowBigGalleryItem {
      height: 9rem;
      width: 9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }
  }
}
