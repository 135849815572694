@use '../../../scss/variables' as vars;
@import '../../../scss/mixins';


.navigationWrapperProfile {
  .tabsWrapperProfile {
    overflow-x: auto;

    scroll-snap-align: start;
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    // margin-left: 3px;
    width: 100%;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   display: none;
    //   background: transparent; /* make scrollbar transparent */
    // }
    @media screen and (max-width: 576px) {
      width: 90%;
    }

    .pageTabProfile {
      @include transitionall(0.2s);
      padding: 5px;
      text-decoration: none;
      color: vars.$color-dark;

      &:hover {
        color: vars.$color-dark;
        text-decoration: none;
        background-color: vars.$color-light-gray;
        border-radius: vars.$radius-m vars.$radius-m 0 0;
      }
    }

    .activeTabProfile {
      color: vars.$color-dark;
      text-decoration: none;
      background-color: vars.$color-light-gray;
      border-radius: vars.$radius-m vars.$radius-m 0 0;
    }
  }
}
