.square {
  // padding: 15px;

  // padding: 10px;
  // display: flex;
  // align-items: center;
   width: 100%;
  // justify-content: center;

  .badges {
    position: relative;
    margin-top: -5rem;
    margin-right: 2rem;
    .badge {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      margin-left: -1.5rem;
      @media screen and (max-width: 576px) {
        width: 70px;
        height: 70px;
        margin-top: -2rem;
      }
    }
  }

  .rowTitle {
    @media screen and (max-width: 576px) {
      height: 30px;
    }
    @media screen and (min-width: 768px) {
      height: 40px;
    }
  }

  .dateField {
    @media screen and (max-width: 576px) {
      height: 30px;
    }
    @media screen and (min-width: 768px) {
      height: 30px;
    }
  }
}
