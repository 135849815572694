@use '../../scss/variables' as vars;
.messagesContainer {
  position: absolute;
  z-index: 6;
  // background: rgba(0, 128, 102, 0.8);
}

.successBackground {
  background: rgba(195, 222, 114, 0.8);

  position: relative;
  // z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.errorBackground {
  background: rgba(222, 128, 102, 0.8);
  position: relative;
  // z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.warningBackground {
  background: rgba(242, 184, 69, 0.8);
  position: relative;
  // z-index: 6;
  height: 60px;
  span {
    color: vars.$color-light;
    height: 50px !important;
  }
  button {
    background-color: vars.$color-desall-blue !important;
    opacity: 1 !important;
  }
}

.translateY60 {
  transform: translateY(60px);
}

.translateY120 {
  transform: translateY(120px);
}
.removeBannerButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-desall-blue;
  opacity: 1 !important;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  border: 0px;
  z-index: 10;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-desall-blue, 20%) !important;
  }
}
